/* Style for the input box */
.single-footer-widget input {
    border: none;
    width: 80% !important;
    font-weight: 300;
    background: #191919;
    color: #777;
    padding-left: 20px;
    border-radius: 0;
    font-size: 14px;
    padding: 10px 18px;
  }

  /* Style for my h6 tag */
  h6 {
    color: #fff;
    margin-bottom: 25px;
    font-size: 18px;
    font-weight: 600;
  }

  /* Style for all p tag but i give it a class box to represent it */
  .box {
    color: #777777;
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    font-weight: 300;
    line-height: 1.625em;
    position: relative;
  }

  /* Style for an icon beside input box */
  .single-footer-widget .click-btn {
    color: #fff;
    background: #2d82ec;
    border-radius: 0;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    padding: 6px 12px;
    border: 0;
  }

  /* Style for the four social icon */
  .footer-social a {
    padding-right: 25px;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
  }