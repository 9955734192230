.carousel-item {
  height: 100vh;
  min-height: 300px;
  background: no-repeat center center scroll;
  -webkit-background-size: cover;
  background-size: cover;
}

.carousel-caption {
  bottom: 250px;
}

.carousel-caption h1 {
  font-size: 45px;
  text-transform: uppercase;
  letter-spacing: 2px;
  /* margin-top: 25px; */
  font-family: merienda;
}

/* .carousel-caption p {
  width: 60%;
  margin: auto;
  font-size: 18px;
  line-height: 1.9;
  font-family: poppins;
} */

.carousel-caption h5 {
  font-family: Poppins, sans-serif;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 3px;
}

.carousel-caption a {
  text-transform: uppercase;
  padding: 7px 10px;
  display: inline-block;
  margin-top: 15px;
  border-radius: 5px;
}

.w-100 {
  height: 100vh;
}

.primary-btn {
  background: #fff;
  border: 1px solid transparent;
  color: #222;
  padding-left: 40px;
  padding-right: 40px;
  border-radius: 50px;
}

.primary-btn:hover {
  border: 1px solid #fff;
  background: transparent;
  color: #fff;
}
