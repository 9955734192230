  .navbar-nav a {
    font-family: Poppins, sans-serif;
    padding: 0 8px 0px 8px;
    text-decoration: none;
    display: inline-block;
    color: #C4C4C4;
    font-weight: 600;
    font-size: 16px;
    text-transform: uppercase;
  }
  
  .navbar-light .navbar-brand {
    color: #C4C4C4;
    font-size: 25px;
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: 2px;
  }
  
  .navbar-light .navbar-nav .active > .nav-link,
  .navbar-light .navbar-nav .nav-link.active,
  .navbar-light .navbar-nav .nav-link.show,
  .navbar-light .navbar-nav .show > .nav-link {
    color: #F0A543;
  }
  
  .navbar-light .navbar-nav .nav-link {
    color: #C4C4C4;
  }
  
  .navbar-nav {
    text-align: center;
  }
  
  .nav-link {
    padding: 0.2rem 1rem;
  }
  
  .navbar-light .navbar-nav .nav-link:focus,
  .navbar-light .navbar-nav .nav-link:hover {
    color: #C4C4C4 !important;
  }
  
  @media only screen and (max-width: 767px) {
    .navbar-nav.ml-auto {
      background: rgba(0, 0, 0, 1);
    }
    .navbar-toggler {
      padding: 1px 5px;
      font-size: 18px;
      line-height: 0.3;
      background: #444444;
    }
  }